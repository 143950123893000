/* Portal configurations - global scope */

/* Define a product types for the portal.
 * id: product type's id
 * name: product type's name
 * available:
 */
const helper = {};

const accent_color = 'version1'; // color schema of the portal

const portal_name = 'Carefizz';

const activate_website = 1; // 1(all), 2(only portal), 3(only landing)

const landing_name = "Carefizz"; // FirstPortal, EbookPortal

const product_type = 4;

const portal_url = "carefizz.com";

const country_landing_url = [{
    'UK': 'https://premium.carefizz.com/Cf231P/',
    'IE': 'https://premium.carefizz.com/Cf231P/',
    'EE': 'https://premium.carefizz.com/Cf24aZ/',
    'LV': 'https://premium.carefizz.com/Cf2PpQ/',
    'LT': 'https://premium.carefizz.com/AQ2UqU/',
    'SK': 'https://premium.carefizz.com/PM2hh4/click/',
	'RO': 'https://premium.carefizz.com/AA2AbE/',
	'FI': 'https://premium.carefizz.com/Cf2918/',
    'DK': 'https://premium.carefizz.com/Cf2ddP/',
    'SE': 'https://premium.carefizz.com/Tr2ddP/',
    'PL': 'https://premium.carefizz.com/CAz912/',
    'CH': 'https://premium.carefizz.com/CAz933/',
    'RS': 'https://premium.carefizz.com/Wwz775/',
    'CZ': 'https://premium.carefizz.com/CUoP12/',
    'LU': 'https://premium.carefizz.com/CpA912/',
    'BG': 'https://premium.carefizz.com/OaoP12/',
    'MK': 'https://premium.carefizz.com/PAmQ23/',
    'SI': 'https://premium.carefizz.com/hyNL9o/'
}];

const country_default_language = {
    'UK': 'EN',
    'IE': 'EN',
    'LU': 'EN',
    'CH': 'DE'
};

const product_menu_items = [
{
    'id': 1,
    'name': 'Home',
    'available': 1,
    'type': 4,
	'banner': 1,
    'isActive': true,
    'countryExclusion': ''
},
{
    'id': 2,
    'name': 'Ebooks',
    'available': 1,
    'type': 3,
	'banner': 0,
    'isActive': false,
    'countryExclusion': ''
},
{
    'id': 3,
    'name': 'Apps',
    'available': 1,
    'type': 2,
	'banner': 0,
    'isActive': false,
    'countryExclusion': 'CZ'
},
{
    'id': 4,
    'name': 'Videos',
    'available': 1,
    'type': 5,
	'banner': 0,
    'isActive': false,
    'countryExclusion': 'CZ'
}];

const language_phones_format = {
    UK: '+44xxxxxxxxx',
    IE: '353xxxxxxxx',
	FI: '04xxxxxxxx',
	EE: '5xxxxxxx',
    LV: '2xxxxxxx',
    LT: '6xxxxxxx',
	RO: '07xxxxxxxx',
	SK: '09xxxxxxxx',
    DK: '47xxxxxxxxx',
    SE: '07xxxxxxxx',
    PL: 'xxxxxxxxx',
    CH: '41xxxxxxxxx',
    RS: '06xxxxxxxx',
    CZ: '7xxxxxxxx',
    LU: '352xxxxxxxxx',
    BG: '08xxxxxxxx',
    MK: '389xxxxxxxx',
    SI: '0xxxxxxxx'
};

/*
 * Rating and Favourite feature enabled (1, 0)
 */
const use_rating = 1;
const use_favourite = 1;

// apis
helper.api_PhoneNumber = "https://api.hubdelivery.io/landing/providePhoneNumberV2";
helper.api_ValidatePin = "https://api.hubdelivery.io/landing/validatePinCodeV2";
helper.api_SendAccessCodeByEmail = "https://api.hubdelivery.io/landing/sendAccessCodeByEmail";

helper.api_Search = 'https://api.hubdelivery.io/store/searchProducts';

// tracking api url
helper.trackingAPI = "https://keepan.eyeonev.com/ent";
helper.ratingsAPI = "https://api.hubdelivery.io/store/addProductRating";
helper.favouriteAPI = "https://api.hubdelivery.io/store/addToFavourite";
helper.favouriteRemoveAPI = "https://api.hubdelivery.io/store/removeFromFavourite";

// tracking default and generated values
helper.affiliate_landing = "AFFED8bcEzc";
helper.source_landing = "995PROVSUB996";
helper.landing_page_id = 'LPCPDT5FHRR';

// apis
helper.landingUrl = country_landing_url;
helper.languagePhoneFormat = language_phones_format;
helper.countryDefaultLanguage = country_default_language;
helper.menu = product_menu_items;
helper.portal_name = portal_name;
helper.portal_type = product_type;
helper.landing_name = landing_name;
helper.portal_url = portal_url;
helper.accent_color = accent_color;
helper.actice_website = activate_website;
helper.hasStars = use_rating;
helper.hasFavourite = use_favourite;

export const helpers = helper;